<template>
	<div class="app-container">
		<el-row>
			<el-col :span="9">
				<el-container>
					<el-header>
						<el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch"
						  label-width="68px"> 
						  <el-form-item label="设备名称" prop="deviceName">
						    <el-input v-model="queryParams.deviceName" placeholder="请输入设备名称" clearable @keyup.enter.native="handleQuery" />
						  </el-form-item> 
						  <el-form-item>
						    <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
							<el-button type="primary" plain icon="el-icon-plus" size="mini" @click="addSb">新增设备</el-button>
						  </el-form-item>
						</el-form> 
					</el-header>
					<el-main>
						<el-table v-loading="loading" :data="sblist" style="margin-top: 20px;" @row-click="selSb">
						  <el-table-column type="selection" width="55" align="center" />
						  <el-table-column label="序号" type="index" width="50" align="center">
						    <template slot-scope="scope">
						      <span>{{
						        (queryParams.pageNum - 1) * queryParams.pageSize +
						        scope.$index +
						        1
						      }}</span>
						    </template>
						  </el-table-column>
						  <el-table-column label="设备名称" align="center" prop="deviceName" /> 
						  <el-table-column label="设备标识" align="center" prop="deviceIden" /> 
						  <el-table-column label="设备地址" align="center" prop="deviceAddr" />
						  <el-table-column label="通讯协议" align="center" prop="protocol" />
						  <el-table-column label="设备类型" align="center" prop="deviceType" />
						  <el-table-column label="状态" align="center" prop="status">
						    <template slot-scope="scope">
						      <el-tag :type="scope.row.status=='1'?'success':'info'">{{scope.row.status=='1'?'在线':'离线'}}</el-tag>
						    </template>
						  </el-table-column>
						  <el-table-column label="启用状态" width="80px" align="center" prop="enableFlag">
						    <template slot-scope="scope">
						      <el-tag :type="scope.row.enable=='1'?'success':'info'">{{scope.row.enable=='1'?'启用':'禁用'}}</el-tag>
						    </template>
						  </el-table-column>
						  <el-table-column label="操作" width="90px" fixed="right" align="center" class-name="small-padding fixed-width">
						    <template slot-scope="scope">
						      <el-button size="mini" type="text" icon="el-icon-edit" @click="XgSb(scope.row)">修改</el-button>
						      <el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(scope.row)">删除</el-button>
						      <el-button size="mini" type="text" :icon="scope.row.enable=='0'?'el-icon-turn-off':'el-icon-open'" @click.stop="Qy(scope.row)">{{scope.row.enable=='0'?'启用':'禁用'}}</el-button>
						    </template>
						  </el-table-column>
						</el-table>
					</el-main>
				</el-container>
			</el-col>
			<el-col :span="15">
				<el-container>
					<el-header>
						<el-form size="small" :inline="true" label-width="68px">  
						  <el-form-item>
						    <el-button type="primary" plain icon="el-icon-plus" size="mini" :disabled="deviceId==''?true:false"
						    @click="handleAdd">测点新增</el-button>
						  </el-form-item>
						</el-form> 
					</el-header>
					<el-main>
						<el-table v-loading="loading" :data="CdList" style="margin-top: 20px;">
						  <el-table-column type="selection" width="55" align="center" />
						  <el-table-column label="序号" type="index" width="50" align="center">
						    <template slot-scope="scope">
						      <span>{{
						        (queryParams.pageNum - 1) * queryParams.pageSize +
						        scope.$index +
						        1
						      }}</span>
						    </template>
						  </el-table-column>
						  <el-table-column label="测点名称" align="center" prop="pointName" /> 
						  <el-table-column label="测点标识" align="center" prop="pointIden" /> 
						  <el-table-column label="测点地址" align="center" prop="registerAddress" />
						  <el-table-column label="测点类型" align="center" prop="pointType">
							  <template slot-scope="scope">
							    <span>{{scope.row.pointType=='analog'?'遥测':'遥信'}}</span>
							  </template>
						  </el-table-column>
						  <el-table-column label="类型" align="center" prop="addressType" />
						  <el-table-column label="控制功能码" align="center" prop="controlCode" />
						  <el-table-column label="偏移量" align="center" prop="offset" />
						  <el-table-column label="倍率" align="center" prop="multiplier" />
						  <!-- <el-table-column label="读取个数" align="center" prop="readNumber" /> -->
						  <el-table-column label="数据值" align="center" prop="reportData" />
						  <el-table-column label="操作" width="120px" align="center" fixed="right" class-name="small-padding fixed-width">
						    <template slot-scope="scope">
						      <el-button size="mini" type="text" icon="el-icon-edit" @click="handleUpdate(scope.row)">修改</el-button>
						      <el-button size="mini" type="text" icon="el-icon-delete" @click="CdhandleDelete(scope.row)">删除</el-button>
						    </template>
						  </el-table-column>
						</el-table>
					</el-main>
				</el-container>
			</el-col>
		</el-row>
		
		<el-dialog :title="title" :visible.sync="open" width="900" append-to-body >
		  <el-form ref="form" :model="form" :rules="rules" label-width="100px">
		    <el-row>
				<el-col :span="12">
					<el-form-item label="设备名称" prop="deviceName">
					  <el-input v-model="form.deviceName" placeholder="请输入设备名称" style="width: 100%" />
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="通讯协议" prop="protocol">
					  <el-select placeholder="请选择通讯协议" v-model="form.protocol" style="width: 100%">
						<el-option value="Modbus">Modbus</el-option>
						<el-option value="Modbus_Non_standard">Modbus_Non_standard</el-option>
						<el-option value="Centralink_North">Centralink_North</el-option>
						<el-option value="Snmp">Snmp</el-option>
					  </el-select>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="12">
					<el-form-item label="设备地址" prop="deviceAddr">
					  <el-input v-model="form.deviceAddr" placeholder="请输入设备地址" style="width: 100%" />
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="设备类型" prop="deviceType">
					  <el-select placeholder="请选择设备类型" v-model="form.deviceType" style="width: 100%">
					  	<el-option value="Modbus-RTU">Modbus-RTU</el-option>
					  	<el-option value="Modbus-ASCII">Modbus-ASCII</el-option>
						<el-option value="Modbus-TCP">Modbus-TCP</el-option>
					  </el-select>
					  <!-- <el-input v-model="form.deviceType" placeholder="请输入设备类型" style="width: 100%" /> -->
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="12">
					<el-form-item label="IP" prop="ip">
					  <el-input v-model="form.ip" placeholder="请输入IP" style="width: 100%" />
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="端口" prop="port">
					  <el-input v-model="form.port" placeholder="请输入端口" style="width: 100%" />
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="12">
					<el-form-item label="启用状态" prop="enable">
						<el-select placeholder="请选择启用状态" v-model="form.enable" style="width: 100%">
							<el-option label="启用" value="1"></el-option>
							<el-option label="禁用" value="0"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="停止位" prop="stopBit">
					  <el-input v-model="form.stopBit" placeholder="请输入停止位" style="width: 100%" />
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="12">
					<el-form-item label="数据位" prop="dataBit">
					  <el-input v-model="form.dataBit" placeholder="请输入数据位" style="width: 100%" />
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="波特率" prop="baudRate">
					  <el-input v-model="form.baudRate" placeholder="请输入波特率" style="width: 100%" />
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="12">
					<el-form-item label="校验位" prop="checkBit">
					  <el-input v-model="form.checkBit" placeholder="请输入校验位" style="width: 100%" />
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="超时时间" prop="timeout">
					  <el-input v-model="form.timeout" placeholder="请输入超时时间" style="width: 100%" >
						  <template slot="append">秒</template>
					  </el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="12">
					<el-form-item label="crc16校验" prop="isVerify">
					  <el-select placeholder="是否crc16校验" v-model="form.isVerify" style="width: 100%">
						<el-option label="是" value="是"></el-option>
						<el-option label="否" value="否"></el-option> 
					  </el-select>
					  <!-- <el-input v-model="form.isVerify" placeholder="请输入crc16校验" style="width: 100%" /> -->
					</el-form-item>
				</el-col> 
				<el-col :span="12"> 
					<el-form-item label="设备标识" prop="deviceIden">
					  <el-input v-model="form.deviceIden" placeholder="请输入设备标识" style="width: 100%" />
					</el-form-item> 
				</el-col> 
			</el-row>  
		  </el-form>
		  <div slot="footer" class="dialog-footer">
		    <el-button type="primary" @click="submitForm">确 定</el-button>
		    <el-button @click="cancel">取 消</el-button>
		  </div>
		</el-dialog>
		<el-dialog :title="Cdtitle" :visible.sync="Cdopen" width="900" append-to-body >
		  <el-form ref="Cdform" :model="Cdform" :rules="Cdrules" label-width="100px">
		    <el-row>
				<el-col :span="12">
					<el-form-item label="测点名称" prop="pointName">
					  <el-input v-model="Cdform.pointName" placeholder="请输入测点名称" style="width: 100%" />
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="测点标识" prop="pointIden">
					  <el-input v-model="Cdform.pointIden" placeholder="请输入测点标识" style="width: 100%" />
					</el-form-item>
				</el-col>
			</el-row> 
			<el-row>
				<el-col :span="12">
					<el-form-item label="测点地址" prop="registerAddress">
					  <el-input v-model="Cdform.registerAddress" placeholder="请输入测点地址" style="width: 100%" />
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="测点类型" prop="pointType">
					  <el-select placeholder="请选择通讯协议" v-model="Cdform.pointType" style="width: 100%">
						<el-option label="遥信" value="state"></el-option>
						<el-option label="遥测" value="analog"></el-option> 
					  </el-select> 
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<!-- <el-col :span="12">
					<el-form-item label="采集时间" prop="collectTime">
					  <el-date-picker clearable v-model="Cdform.collectTime" type="date" value-format="yyyy-MM-dd"
					    placeholder="请选择采集时间">
					  </el-date-picker>
					</el-form-item>
				</el-col> -->
				<el-col :span="12">
					<el-form-item label="类型" prop="type">
					  <el-select placeholder="请选择通讯协议" v-model="Cdform.type" style="width: 100%">
						<el-option value="01" label="01 Coil Status(0x)"></el-option>
						<el-option value="02" label="02 Input Status(1x)"></el-option>
						<el-option value="03" label="03 Holding Register(4x)"></el-option>
						<el-option value="04" label="04 Input Registers(3x)"></el-option>
					  </el-select> 
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="倍率" prop="multiplier">
					  <el-input v-model="Cdform.multiplier" placeholder="请输入倍率" style="width: 100%" />
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="12">
					<el-form-item label="控制功能码" prop="controlCode">
					  <el-input v-model="Cdform.controlCode" placeholder="请输入控制功能码" style="width: 100%" />
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="偏移量" prop="offset">
					  <el-input v-model="Cdform.offset" placeholder="请输入偏移量" style="width: 100%" />
					</el-form-item>
				</el-col>
			</el-row> 
			<el-row>
				<el-col :span="12">
					<el-form-item label="小数位" prop="pointDecimal">
					  <el-input v-model="Cdform.pointDecimal" placeholder="请输入小数位" style="width: 100%" />
					</el-form-item>
				</el-col>
			</el-row> 
		  </el-form>
		  <div slot="footer" class="dialog-footer">
		    <el-button type="primary" @click="CdsubmitForm">确 定</el-button>
		    <el-button @click="Cdopen=false">取 消</el-button>
		  </div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				open:false,
				timer:"",
				showSearch:true,
				queryParams: {
				  pageNum: 1,
				  pageSize: 10,
				  deviceName: null, 
				},
				// 表单参数
				form: {
					deviceName:null,
					deviceIden:null,
					deviceGroupId:null,
					deviceAddr:null,
					deviceGuid:null, 
					protocol:'',
					deviceType:null,
					ip:null,
					port:null,
					enable:null,
					stopBit:null,
					dataBit:null,
					baudRate:null,
					checkBit:null,
					timeout:null,
					isVerify:null,
					deleted:"0"
				},
				// 表单校验
				rules: {
				  id: [{
				    required: true,
				    message: "id不能为空",
				    trigger: "blur"
				  }],
				  deviceName: [{
				    required: true,
				    message: "设备名称不能为空",
				    trigger: "blur"
				  }],
				  deviceIden: [{
				    required: true,
				    message: "设备标识不能为空",
				    trigger: "blur"
				  }],
				  deviceAddr: [{
				    required: true,
				    message: "设备地址不能为空",
				    trigger: "blur"
				  }],
				  deviceType: [{
				    required: true,
				    message: "设备类型不能为空",
				    trigger: "blur"
				  }],
				  ip: [{
				    required: true,
				    message: "IP不能为空",
				    trigger: "blur"
				  }], 
				  port: [{
				    required: true,
				    message: "端口不能为空",
				    trigger: "blur"
				  }], 
				  enable: [{
				    required: true,
				    message: "启用状态不能为空",
				    trigger: "blur"
				  }], 
				  stopBit: [{
				    required: true,
				    message: "停止位不能为空",
				    trigger: "blur"
				  }], 
				  dataBit: [{
				    required: true,
				    message: "数据位不能为空",
				    trigger: "blur"
				  }], 
				  baudRate: [{
				    required: true,
				    message: "波特率不能为空",
				    trigger: "blur"
				  }], 
				  checkBit: [{
				    required: true,
				    message: "校验位不能为空",
				    trigger: "blur"
				  }], 
				  timeout: [{
				    required: true,
				    message: "超时时间不能为空",
				    trigger: "blur"
				  }], 
				  isVerify: [{
				    required: true,
				    message: "crc16校验不能为空",
				    trigger: "blur"
				  }],  
				  protocol: [{
				    required: true,
				    message: "crc16校验不能为空",
				    trigger: "blur"
				  }],  
				} ,
				title:'',
				sblist:[],
				CdList:[],
				deviceId:'',
				selSbmessage:{},
				loading:false,
				Cdtitle:'',
				Cdopen:false,
				Cdform:{
					deviceId:null,
					pointName:null,
					pointIden:null,
					registerAddress:null,
					pointType:null,
					collectTime:null,
					type:null,
					controlCode:null,
					offset:null,
					multiplier:null,
					readNumber:null,
					pointDecimal:null
				},
				Cdrules:{
					pointName: [{
					  required: true,
					  message: "测点名称不能为空",
					  trigger: "blur"
					}],
					pointIden: [{
					  required: true,
					  message: "测点标识不能为空",
					  trigger: "blur"
					}],
					registerAddress: [{
					  required: true,
					  message: "测点地址不能为空",
					  trigger: "blur"
					}],
					pointType: [{
					  required: true,
					  message: "测点类型不能为空",
					  trigger: "blur"
					}],
					collectTime: [{
					  required: true,
					  message: "采集时间不能为空",
					  trigger: "blur"
					}], 
					type: [{
					  required: true,
					  message: "类型不能为空",
					  trigger: "blur"
					}], 
					controlCode: [{
					  required: true,
					  message: "控制功能码不能为空",
					  trigger: "blur"
					}], 
					offset: [{
					  required: true,
					  message: "偏移量不能为空",
					  trigger: "blur"
					}], 
					multiplier: [{
					  required: true,
					  message: "倍率不能为空",
					  trigger: "blur"
					}], 
					readNumber: [{
					  required: true,
					  message: "读取个数不能为空",
					  trigger: "blur"
					}], 
					pointDecimal: [{
					  required: true,
					  message: "小数位不能为空",
					  trigger: "blur"
					}], 
				}
			}
		}, 
		created(){
			this.getList()
			this.getdata()
		},
		methods:{ 
			/** 搜索按钮操作 */
			handleQuery() {
			  this.queryParams.pageNum = 1;
			  this.getList();
			},
			/** 重置按钮操作 */
			resetQuery() {
			  this.resetForm("queryForm");
			  this.handleQuery();
			},
			getList(){
				this.loading = true;
				this.getRequest('/modbus-device/getAll',this.queryParams).then(resp =>{
				    if (resp){ 
						this.sblist=resp.data.modbusDevice;
						console.log(resp.data)
						this.loading = false;
				    }
				})
			},
			selSb(row,event,column){  
				this.deviceId=row.id;
				this.selSbmessage=row;
				clearInterval(this.timer)
				this.getdata();
			},
			getdata(){
				this.getRequest('/modbus-point/list?deviceId='+this.deviceId).then(resp =>{
				    if (resp){ 
						this.CdList=resp.data.page.list
						this.timer = setInterval(this.CdCdsee,10000)
				    }
				})
			},
			CdCdsee(){   
				if(this.CdList.length!=0){
					let modbusPoints=[];
					this.CdList.map((row)=>{
						modbusPoints.push({
							"id":row.id,
							"pointName": row.pointName,
							"registerAddress":row.registerAddress,
							"pointIden": row.pointIden,  
							"param":{
								"type": 2,
								"multiplier": row.multiplier,
								"decimal":row.pointDecimal,
								"offset": row.offset,
								"addressType": row.type, 
								"readNumber": '10' 
							} 
						})
					})
					let cdmess={
						"deviceId": this.selSbmessage.id,
						// "deviceIden": this.selSbmessage.deviceIden,
						// "deviceAddr": this.selSbmessage.deviceAddr,
						// "protocol": this.selSbmessage.protocol,
						// "deviceType": this.selSbmessage.deviceType,
						// "ip": this.selSbmessage.ip,
						// "port": this.selSbmessage.port,
						// "isVerify": 0,
						// "modbusPoints": modbusPoints
					}
					this.getRequest('/modbus-point/getPointData',cdmess).then(resp => {
					// this.postRequest('/modbus/readPoint',cdmess).then(resp => {
						if (resp.data){    
							let result=resp.data;
							let this_=this;
							this.CdList.forEach(function(row,index){
								for(var i=0;i<result.length;i++){  
									if(result[i].pointId==row.id){ 
										this_.CdList[index].reportData=resp.data[i].data.toString();
									}
								}
							}) 
					    }
					});
				}else{
					clearInterval(this.timer)
				} 
			},
			reset() {
			  this.form = {
			    deviceName:null,
			    deviceIden:null,
			    deviceGroupId:null,
			    deviceAddr:null,
			    deviceGuid:null, 
			    protocol:'',
			    deviceType:null,
			    ip:null,
			    port:null,
			    enable:null,
			    stopBit:null,
			    dataBit:null,
			    baudRate:null,
			    checkBit:null,
			    timeout:null,
			    isVerify:null,
			    deleted:"0"
				};
			  this.resetForm("form");
			},
			Cdreset() {
			  this.Cdform = {
			    deviceId:null,
			    pointName:null,
			    pointIden:null,
			    registerAddress:null,
			    pointType:null,
			    collectTime:null,
			    type:null,
			    controlCode:null,
			    offset:null,
			    multiplier:null,
			    readNumber:null,
			    pointDecimal:null
				}; 
			},
			addSb(){
				this.title="新增设备"
				this.open=true;
				this.reset()
			},
			XgSb(row){
				console.log(row)
				this.title="修改设备"
				this.open=true;
				this.reset();
				row.enable=row.enable.toString()
				row.isVerify=row.isVerify==true?'是':'否'
				this.form=row;
			},
			submitForm(){
				this.$refs["form"].validate(valid => {
				  if (valid) {
					  console.log(this.form)
				    this.form.isVerify=this.form.isVerify=='是'?'true':'false'
					if (this.form.id != null) {
				      //编辑
					  this.postRequest('/modbus-device/update',this.form).then(resp => {
						if (resp){
						
							this.getList()
							this.open = false;
					      }
					  });
				    } else {
				      //新增
					  this.postRequest('/modbus-device/add',this.form).then(resp => {
					      if (resp){
					        
							this.getList()
							this.open = false;
					      }
					  });
				    }
				  }
				});
			}, 
			CdsubmitForm(){
				this.$refs["Cdform"].validate(valid => {
				  if (valid) {  
					if (this.Cdform.id != null) {
				      //编辑
					  this.postRequest('/modbus-point/batchUpdate',[this.Cdform]).then(resp => {
						if (resp){
						console.log(resp)
							this.getdata()
							this.Cdopen = false;
					      }
					  });
				    } else {
				      //新增
					  this.postRequest('/modbus-point/save',[this.Cdform]).then(resp => {
					      if (resp){
					        console.log(resp)
							this.getdata()
							this.Cdopen = false;
					      }
					  });
				    }
				  }
				});
			},
			handleDelete(row) {
			  this.$confirm('此操作将永久删除此设备, 是否继续?', '提示', {
			      confirmButtonText: '确定',
			      cancelButtonText: '取消',
			      type: 'warning'
			  }).then(() => { 
			      this.postRequest('/modbus-device/delete?id='+row.id).then(resp =>{
			          if (resp){
			              this.getList();
			          }
			      });
			  }).catch(() => {
			      this.$message({
			          type: 'info',
			          message: '已取消删除'
			      });
			  }); 
			},
			handleAdd(){
				this.Cdtitle="新增测点"
				this.Cdreset()
				this.Cdform.deviceId=this.deviceId;
				this.Cdopen=true;
				
			},
			handleUpdate(row){
				console.log(row);
				this.Cdtitle="编辑测点" 
				this.Cdreset()
				this.Cdopen=true;
				row.pointType=row.pointType
				this.Cdform=row;
			},
			Qy(row){
				let txt=row.enable=='0'?'是否启用此设备?':'是否禁用此设备?'
				let enable=row.enable=='0'?'1':'0';
				this.$confirm(txt, '提示', {
				    confirmButtonText: '确定',
				    cancelButtonText: '取消',
				    type: 'warning'
				}).then(() => { 
				    this.postRequest('/modbus-device/enable?id='+row.id+'&enable='+enable).then(resp =>{
				        if (resp){
				            this.getList();
				        }
				    });
				}).catch(() => {
				    this.$message({
				        type: 'info',
				        message: '已取消'
				    });
				}); 
			},
			CdhandleDelete(row){
				this.$confirm('此操作将永久删除此设备, 是否继续?', '提示', {
				    confirmButtonText: '确定',
				    cancelButtonText: '取消',
				    type: 'warning'
				}).then(() => { 
				    this.getRequest('/modbus-point/delete?id='+row.id).then(resp =>{
				        if (resp){
				            this.getdata();
				        }
				    });
				}).catch(() => {
				    this.$message({
				        type: 'info',
				        message: '已取消删除'
				    });
				}); 
			},
			// 表单重置
			reset() {
			  this.form = {
			    id: null,
			    dbpc: null,
			    dbrq: null,
			    dbsl: null,
			    blry: null,
			    shr: null,
			    remark: null, 
			  }; 
			},
			// 取消按钮
			cancel() {
			  this.open = false;
			  this.reset();
			},
		},
		beforeDestroy() {
			clearInterval(this.timer)
		}
	}
</script>
<style lang="scss">
	.el-main{
		padding: 0px !important;
	}
	.el-card{
		border: none !important;
		box-shadow: none !important;
	}
	@import url(../../../../assets/css/reset.css);
	.leftNav>li{
	  margin-bottom: 5px;
	}
	.leftNav>li>div{
	  background-color: #fff;
	  border-radius: 10px;
	  display: flex;
	  justify-content: space-around;
	  -webkit-box-align: baseline;
	  padding: 5px;
	  align-items: center;
	}
	.leftNav>li>div img{
		width: 20px;
	}
	.leftNav>li>div:hover{
	  cursor: pointer;
	}
	.leftNav>li>div .name{
	  display: inline-block;
	  width: 20%;
	} 
	.el-card{
		height: 88vh;
	} 
	
	.app-container{
		height: 95vh;
	}
	.el-container{
		height: 90.2%;
	}
	.el-divider--vertical{
		height: 100%;
	}
</style>